.Container {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Form {
    margin: 20px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: adds subtle shadow */
    border-radius: 8px; /* Optional: rounds corners */
}

@media (min-width: 768px) { /* Adjust this breakpoint as needed */
    .Form {
        width: 30%;
        margin: 20px auto;
        padding: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional: adds subtle shadow */
        border-radius: 8px; /* Optional: rounds corners */
    }

}


.FormControl {
    width: 80%;
    margin-right: 20px;
    margin-bottom: 15px;
}

.FormSelect {
    width: 80%;
    flex: 1 1 auto; /* Allows the fields to grow and fill the available space */
    margin-right: 0;
    margin-bottom: 15px;
    margin-left: 40px;
}
.FormYNSelect {
    width: 100%;
    min-width:50px;
    margin-bottom: 15px;
    margin-top:15px;

}
.Delete {
    border-radius: 100%; /* Fully round shape */
    font-size: 0.75rem; /* Smaller font size */
    line-height: 1; /* Adjust line height to ensure the content is centered */
    margin-bottom: 15px;
}
.RecordBackButton{
    min-width:15%;
}
.FormName {
    font-weight: bold;
    justify-content: left;
    margin-top: 15px;
}
.FormNameEnter {
    width: 100%;
    margin-top: 15px;
}