@media print {
	.container {
		width: 100%;
		max-width: 100%;
		padding: 15px;
	}

	.chart, .print-chart {
		width: 80%;
		height: auto;
	}

	h1 {
		width: 100%;
		max-width: 100%;
	}
}

.print-only {
	display: none;
}


@media print {
	@page {
		size: A3;
		margin: 10mm;
	}

	body {
		zoom: 0.6;
	}

	.print-only {
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

		.print-only .logo {
			width: 100px;
		}

		.print-only h1 {
			flex-grow: 1;
			text-align: center;
			padding: 100px;
		}

	.heading {
		transform: translateY(-10%);
	}
}

.chart-wrapper {
	overflow-x: scroll;
	max-width: 100%;
}

@media print {
	.chart-wrapper {
		overflow-x: visible;
		width: 100%;
	}
}

