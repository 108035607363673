/* AddEmployees.css */
.container-custom {
    width: 80%;
    margin: 0 auto;
}

.table-custom {
    width: 80%;
    margin: 0 auto;
}
