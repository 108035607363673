.navbar-custom {
    background-color: #198754; /* This is the color of the Bootstrap 'success' variant as of version 4.3.1 */
}

.nav-link-custom {
    color: #ffffff !important; /* This is to ensure that the link color is white */
    padding: 10px 15px;
    margin-right: 10px; /* Space out nav items */
}



.logout-custom {
    color: #ffffff !important; /* This is to ensure that the link color is white */
    padding: 10px 15px;
}

/* For Desktop */
@media screen and (min-width: 992px) {
    .logout-custom {
        margin-right: 50px; /* Space out nav items */
    }
}

/* For Mobile */
@media screen and (max-width: 991px) {
    .logout-custom {
        margin-right: 0px; /* Remove the extra space on mobile */
    }
}

.nav-link-dash {
    color: #ffffff !important; /* This is to ensure that the link color is white */
    padding: 0px 15px;
}

.nav-link-custom:hover {
    color: #c3e6cb !important; /* This is to lighten the color of the text on hover */
}

.notification-icon-container {
    position: relative;
    padding: 0px 15px;
    
}

.notification-icon {
    position: relative;
    padding: 0px 15px;
}

.notification-icon .badge {
        position: absolute;
        top: -10px;
        right: -10px;
        padding: 5px 10px;
        border-radius: 50%;
        color: white;
        font-size: 0.75rem;
    }
.navbar-custom .dropdown-center .dropdown-menu .dropdown-item {
    white-space: normal !important;
}
@media (max-width: 991.98px) {
    .navbar-custom .dropdown-center .dropdown-menu {
        min-width: 80vw !important; /* Set the dropdown menu width to 95% of the screen width */
        transform: translateX(-40%) !important; /* Adjust horizontal positioning to truly center it */
        text-align: left; /* Align the text to the left within the dropdown */
    }
    .dropdown-item {
        white-space: normal; /* Allows the text to wrap */
    }
}

@media screen and (min-width: 992px) {
    .navbar-custom .dropdown-center .dropdown-menu {
        min-width: 500px; /* Adjust this as needed to fit the wider dropdown items */
        /* Other adjustments as needed to center the dropdown, etc. */
    }
}
