.Container{
    width:100%;
}
.table-header i {
    cursor:grab;
    margin-left: 15px;
}


.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
    display: flex;
    justify-content: center;
    align-items: center;
}

.PageTable {
    margin-left: 15%;
    justify-content: center;
}

.print-only .logo {
    width: 100px;
}
.print-only h1 {
    flex-grow: 1;
    text-align: center;
    padding: 100px;
    margin-left:100px;
}
.print-only-table {
    width: 70%; /* Adjust width as needed */
    margin-left: 3%; /* Centers the table and adds space above */
    margin-right:7%;
}

    /* Ensure the table headers and body are styled for legibility */
    .print-only-table th,
    .print-only-table td {
        border: 1px solid #000; /* Optional: ensures borders are visible in print */
        padding: 8px; /* Adjust padding for table cells */
    }

@media print {
    @page {
        size: A4 portrait; /* Change to A4 landscape to accommodate content intended for A3 */
        margin: 10mm; /* Adjust margins as needed to fit content */
    }

    body, html {
        width: 297mm; /* A3 width scaled down to fit A4 landscape */
        height: 210mm; /* A3 height scaled down */
        page-break-after: avoid; /* Avoid breaking inside elements between pages */
        page-break-before: avoid;
    }

    .print-only .logo {
        width: 100px; /* Adjust if necessary */
        margin: 0 auto; /* Center the logo */
    }

    .print-only h1 {
        font-size: 24px; /* Adjust font size for better fit */
        margin: 20px 0; /* Adjust top and bottom margin */
    }

    .print-only-table {
        width: 100%; /* Use full width for table */
        margin: 0; /* Reset margins for table */
    }

        /* Ensure table headers and cells are visible and legible */
        .print-only-table th,
        .print-only-table td {
            border: 1px solid #000; /* Ensure borders are printed */
            padding: 10px; /* Adjust padding for legibility */
        }
}
