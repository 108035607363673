/* Profile.css */

.profile-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
}

.card-container {
    margin-bottom: 20px;
}

.back-button {
    margin-top: 10px;
}
