.Container {
    width: 100%;
    height: 70%;
}




.recordbutton {
    min-width:300px;
}

.secondarybutton {
    min-width: 300px;
}