/* For very small screens: less than 390px */
@media (max-width: 390px) {
    .w-100.m-2 {
        height: 60px;
    }
}

/* For medium screens: between 391px and 767px, reset to default */
@media (min-width: 391px) and (max-width: 767px) {
    .w-100.m-2 {
        height: auto; /* Resets the height to default */
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .w-100.m-2 {
        height: 60px; /* Resets the height to default */
    }
}