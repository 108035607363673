.chatbot-container {
	position: fixed;
	bottom: 50px;
	right: 20px;
	width: 500px;
	pointer-events: auto;
	z-index: 1000;
}
	.chatbot-container.open {
		pointer-events: auto;
		bottom: 20px;
	}

.chatbot-button {
	background-color: #188754; /* Using the company color */
	border: none;
	border-radius: 50%;
	width: 75px;
	height: 75px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

	.chatbot-button img {
		width: 100%;
		height: auto;
	}

.chat-interface {
	background-color: white;
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	max-height: 750px; /* Adjust height as needed */
	overflow: hidden;
}

	.chat-interface input, .chat-interface button {
		padding: 8px;
		width: 100%;
		box-sizing: border-box;
	}

	.chat-interface button {
		background-color: #188754;
		color: white;
		border: none;
		cursor: pointer;
	}

	.chat-interface p {
		color: black;
	}

.message-container {
	flex: 1;
	overflow-y: auto;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
}

.message {
	padding: 10px;
	border-radius: 5px;
	margin-bottom: 10px;
	max-width: 80%;
}

	.message.user {
		background: #f1f1f1;
		align-self: flex-end;
		text-align: right;
	}

	.message.bot {
		background: #18875473;
		align-self: flex-start;
	}

.equipment-select {
	margin-bottom: 10px;
}
@media (max-width: 768px) {

	.chatbot-container {
		position: fixed;
		bottom: 50px;
		width: 90%;
	}

	.chatbot-container.open {
		position: fixed;
		bottom: 20px;
		width: 90%;
		pointer-events: auto;
	}

}