@media print {
    .container {
        width: 100%;
        max-width: 100%;
        padding: 15px;
    }

    .chart, .print-chart {
        width: 80%;
        height: auto;
    }

    h1 {
        width: 100%;
        max-width: 100%;
    }
    h2 {
        width: 100%;
        max-width: 100%;
    }
}

.print-only {
    display: none;
}


@media print {
    @page {
        size: A3;
        margin-top: 10mm;
        margin-right: 10mm;
        margin-bottom: 10mm;
        margin-left: 30mm; /* Increase the left margin */
    }

    body {
        zoom: 0.6;
        padding-left: 15mm; /* adjust as necessary */
    }
    .hide-on-print {
        display: none;
    }
    .print-only {
        display: flex;
        align-items: center;
    }

        .print-only .logo {
            width: 100px;
        }

        .print-only h1 {
            text-align: center;
        }

        .print-only h2 {
            text-align: center;
        }

    .heading {
        transform: translateY(-10%);
    }
}

.chart-wrapper {
    overflow-x: scroll;
    max-width: 100%;
}

@media print {
    .chart-wrapper {
        overflow-x: visible;
        width: 100%;
    }
}
