.Container {
    width: 80%;
    height: 70%;
    justify-content: center;

}



.Forms {
    width: 300px;
}

.Cards {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 50px; /* Adds a gap between cards */
    transition: all 0.3s ease-in-out; /* Smooth transition for the hover effect */
}

.card:hover {
    box-shadow: 0 10px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
}

/* Media query for larger screens */
@media (min-width: 768px) { /* Adjust this breakpoint as needed */
    .Cards {
        justify-content: flex-start !important; /* Override Bootstrap's justify-content-center */
    }
}
