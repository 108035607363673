@media print {
    .container {
        width: 100%;
        max-width: 100%;
        padding: 15px;
    }

    .chart, .print-chart {
        width: 80%;
        height: auto;
    }

    h1 {
        width: 100%;
        max-width: 100%;
    }
}

.print-only {
    display: none;
}


@media print {
    @page {
        size: A3;
        margin-top: 0mm;
        margin-right: 5mm;
        margin-bottom: 0mm;
        margin-left: 5mm;
    }

    body {
        zoom: 0.9;
    }

    .print-only {
        display: flex;
        flex-direction: row; /* Explicitly set the flex direction to row */
        justify-content: start;
        align-items: flex-start;
    }


        .print-only .logo {
            width: 100px;
        }

        .print-only h1 {
            flex-grow: 1;
            text-align: center;
            padding: 100px;
        }


    .heading {
        transform: translateY(0);
    }
}

.chart-wrapper {
    overflow-x: scroll;
    max-width: 100%;
}

.chart-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.scatter-section {
    width: 100%;
    align-self: center;
}

.chart-wrapper {
    overflow-x: scroll;
    max-width: 100%;
}

@media print {
    .chart-wrapper {
        overflow-x: visible;
        width: 100%;
    }
}

.chart-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; /* align the tables to the top */
    width: 80%;
}

.table-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; /* align the tables to the top */
    width: 80%;
    margin-top: 25px;
    margin-left: 5%;
}

@media print {

    .chart-section > div, .table-section > div {
        flex: 1;
        max-width: 100%;
        min-width: 400px;
    }
}
