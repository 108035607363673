.loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5; /* light grey */
}

.loading-spinner {
    width: 3rem;
    height: 3rem;
}
