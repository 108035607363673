/* signup.css */

/* Container styles */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 1rem); /* Adjust the min-height value */
    width: 100%;
}

.formcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 60px); /* Adjust the min-height value */
    width: 100%;
}

/* Title styles */
h1 {
    margin-bottom: 1rem;
}

/* Form styles */
.form {
    width: 100%;
    max-width: 400px;
    padding: 1rem;
    border-radius: 5px;
}

/* Button container styles */
.button-container {
    display: flex;
    justify-content: center;
    border: none; /* Remove the border */
    align-content:center";"
}

/* Button row styles */
.row-btn {
    display: flex;
    justify-content: center;
    margin-top: 0rem; /* Updated margin-top value */
}

/* Error message styles */
.error {
    margin-top: 1rem;
    color: red;
}
