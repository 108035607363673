/* ViewFaultRecord.css */

.table-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .table-header span {
        margin-right: 1px;
        margin-left: 5px;
    }

    .table-header i {
        cursor: pointer;
        margin-left: 5px;
    }

.table-search-input {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 30px);
    box-sizing: border-box;
}

th {
    text-align: center;
    vertical-align: middle;
}

.table-header i {
    cursor: pointer;
    margin-left: 15px; /* reduced margin-left */
    margin-right: 3px; /* added margin-right for symmetry */
}

td:nth-child(1), th:nth-child(1) { /* Equipment Number */
    width: 15%;
    word-wrap: break-word;
}

td:nth-child(2), th:nth-child(2) { /* Fault */
    width: 15%;
    word-wrap: break-word;
}

td:nth-child(3), th:nth-child(3) { /* Lesson Learnt */
    width: 20%;
    word-wrap: break-word;
}

td:nth-child(4), th:nth-child(4) { /* Vendor Used */
    width: 15%;
    word-wrap: break-word;
}

td:nth-child(5), th:nth-child(5) { /* Vendor Contact Number */
    width: 15%;
    word-wrap: break-word;
}

td:nth-child(6), th:nth-child(6) { /* Vendor Contact Email */
    width: 15%;
    word-wrap: break-word;
}

td:nth-child(7), th:nth-child(7) { /* Approved */
    width: 5%;
    word-wrap: break-word;
}

@media (max-width: 768px){
    .Search-but {
        margin-top: 15px; /* Resets the height to default */
    }
}