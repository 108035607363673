.user-guide-container {
    width: 95%;
    margin: auto; /* Center the container */
}

.accordion-button {
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin-bottom: 5px; /* space between accordion buttons */
    border: none;
    text-align: center; /* Center text horizontally */
    width: 100%;
    border-radius: 8px; /* Rounded edges */
    color: white; /* White text for all buttons */
}

/* Specific style for section 1 */
.section-1 .accordion-button {
    background-color: #188754; /* Green background */
    color: white; /* White text */
    text-align: center; /* Center text horizontally */
}

/* Content style for all sections */
.content {
    background-color: #f2f2f2; /* Light grey background */
    color: black; /* Black text */
    padding: 0.5rem 1rem;
    border-radius: 8px; /* Rounded edges */
}

/* Optional: Add transitions for smooth opening/closing */
.accordion-button, .content {
    transition: all 0.3s ease;
}

    /* Optional: Add hover effect for buttons */
    .accordion-button:hover {
        filter: brightness(90%); /* Slightly darken the button on hover */
    }
